import { AppRouteProps } from "@vdt/component";
import React from "react";

const SubscriberContactDetailsContainer = React.lazy(() => import('./container/SubscriberContactDetailsContainer'))

export const SubscriberContactDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ':id',
        element: <SubscriberContactDetailsContainer/>,
        title: "VDT.VPostal.Subscriber.Contact.Details"
    }
]