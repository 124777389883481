import { AppRouteProps } from "@vdt/component";
import React from "react";

const SubscriberListContainer = React.lazy(() => import("./container/SubscriberListContainer"))

export const SubscriberListRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <SubscriberListContainer/>,
        title: "App.VPostal.Bo.Subscriber.Title"
    }
]