import {
    GenericSuspense,
} from "@vdt/component";
import React from "react";
import { Outlet } from "react-router-dom";
import { AdminLeftSideBar } from "./AdminLeftSideBar";
import "./css/mainLayout.css";

const AdminLayout = () => {


    return (
        <div className="flex flex-row gap-10 relative">
            
            <AdminLeftSideBar />
            <main className="flex-1 parent-layout">
                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </main>
        </div>
    );
};

export default AdminLayout;
