import { AppRouteProps } from "@vdt/component";
import React from "react";
import { SubscriberContactViewsRoutes } from "./views/SubscriberContactViewsRoutes";

const SubscriberContactContainer = React.lazy(() => import('./containers/SubscriberContactContainer'))

export const SubscriberContactRoutes: Array<AppRouteProps> = [
    {
        path: ":id/contacts",
        element: <SubscriberContactContainer/>,
        title: "Vdt.Subscriber.Contacts.Title",
        children: SubscriberContactViewsRoutes
    }
]