import { AppRouteProps } from "@vdt/component";
import React from "react";
import { AboutUserDetailsRoutes } from "./views/about/AboutUserDetailsRoutes";
import { DevicesUserDetailsRoutes } from "./views/devices/DevicesUserDetailsRoutes";
import { ParamsUserDetailsRoutes } from "./views/params/ParamsUserDetailsRoutes";

const UserDetailsContainer = React.lazy(() => import('./containers/UserDetailsContainer'))
export const UserDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'user/:id',
        title: 'Harea.User.List.Details.title',
        element: <UserDetailsContainer />,
        children: [
            ...AboutUserDetailsRoutes,
            ...ParamsUserDetailsRoutes,
            ...DevicesUserDetailsRoutes,
        ],
        roles: ["ORGANISATION_LICENSE"]
    }
]