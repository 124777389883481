import { AppRouteProps } from "@vdt/component";
import React from "react";
import { SettingsViewsRoutes } from "views/SettingsViewsRoutes";

const SettingsContainer = React.lazy(() => import("container/SettingsContainer"));
export const SettingsRoutes = [
    {
        path: "settings/:id",
        element: <SettingsContainer />,
        title: "Vdt.Setting.Title",
        children: SettingsViewsRoutes
    },
] as Array<AppRouteProps>;
