import {
    useRuntimeEnvRolesIncludes,
    useTranslationRoute,
    useUrlActive,
} from "@vdt/component";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useLeftSideBarMenu } from "./hooks/useLeftSideBarMenu";

export const AdminLeftSideBar = () => {

    const roleIncludes = useRuntimeEnvRolesIncludes()
    const url = useTranslationRoute();
    const menuItems = useLeftSideBarMenu();
    const isActive = useUrlActive();

    const transformedMenuItems = useMemo(() => {
        return menuItems.map?.((menu) => {
            const hasRightRole = _.isEmpty(menu?.roles) || !!menu?.roles?.every((role: string) => roleIncludes(role))
            return hasRightRole && menu
        }).filter((item) => !_.isEmpty(item))
    }, [menuItems]);

    return (

        <div className="bg-primary sticky top-0 bottom-0 h-screen flex flex-col items-center gap-[70px] py-10 overflow-auto overflow-x-hidden override-scroll">
            <Link to={url('/')}>
                <img src="/assets/img/logo-inv.png" alt="Vadato" className="h-[40px]" />
            </Link>
           <div className="flex flex-col">
                {
                    transformedMenuItems.map?.((menu: any, key: any) => {
                        return (
                        <Link to={menu.link} key={key} className={`hover:bg-[#E8B500] ${isActive(menu.link || '') && 'bg-[#E8B500]'}`}>
                            <div className="flex flex-col items-center gap-3 text-white py-[16px] px-5">
                                <img src={menu.startIcon!} className="w-[30px]" />
                                <span>{menu.label}</span>
                            </div>
                        </Link>
                    )})
                }
            </div>
        </div>

    );
};
