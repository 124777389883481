import {
    MenuItemProps, useRuntimeEnv, useTranslationRoute
} from "@vdt/component";
import { useMemo } from "react";

export const useLeftSideBarMenu = (): Array<MenuItemProps> => {

    const translationRoute = useTranslationRoute();
    const { runtimeEnv } = useRuntimeEnv()

    return useMemo(() => [
        {
            ke: "Campagnes",
            label: "Campagnes",
            link: translationRoute("campagne"),
            startIcon: "/assets/img/icons/campagnes.png",
            roles: ["ORGANISATION"],
        },
        {
            ke: "Audiences",
            label: "Audiences",
            link: translationRoute("subscriber"),
            startIcon: "/assets/img/icons/audiences.png",
            roles: ["ORGANISATION"],
        },
        {
            ke: "dons",
            label: "Dons",
            link: translationRoute("dons"),
            startIcon: "/assets/img/icons/dons.png",
            roles: ["ORGANISATION"],
        },
        {
            ke: "configuration",
            label: "Configuration",
            link: translationRoute(`user/organisation/${runtimeEnv.organisation?.id}`),
            startIcon: "/assets/img/icons/config.png",
            roles: ["ORGANISATION"],
        },
    ], [])
}