import { AppRouteProps } from "@vdt/component";
import React from "react";
import { SubscriberContactRoutes } from "views/contacts/SubscriberContactRoutes";
import { SubscriberCreateRoutes } from "views/create/SubscriberCreateRoutes";
import { SubscriberListRoutes } from "views/list/SubscriberListRoutes";
const SubscriberContainer = React.lazy(() => import("container/SubscriberContainer"));

export const SubscriberRoutes = [
    {
        path: "subscriber",
        element: <SubscriberContainer />,
        title: "Vdt.Subscriber.Title",
        children: [
            ...SubscriberListRoutes,
            ...SubscriberCreateRoutes,
            ...SubscriberContactRoutes
        ]
    },
] as Array<AppRouteProps>;
