import { AppRouteProps } from "@vdt/component";
import React from "react";

const PayslipContainer = React.lazy(() => import("container/NotificationContainer"));
export const NotificationRoutes = [
    {
        path: "notification",
        element: <PayslipContainer />,
        title: "Harea.Notification.Title",
    },
] as Array<AppRouteProps>;
