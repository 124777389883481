import { AppRouteProps } from "@vdt/component";
import React from "react";

const SubscriberCreateContainer = React.lazy(() => import("./container/SubscriberCreateContainer"));
export const SubscriberCreateRoutes = [
    {
        path: "create",
        element: <SubscriberCreateContainer />,
        title: "Vdt.Subscriber.Create.Title",
    },
] as Array<AppRouteProps>;
