import { AppRouteProps } from "@vdt/component";
import { AdminUserManagementRoutes } from "@vdt/user-management";
import { HomeRoutes } from "./home/HomeRoutes";
import { NotificationRoutes } from "@vdt/notification";
import { TranslationRoutes } from '@vdt/translation'
import { SettingsRoutes } from '@vdt/settings';
import { SubscriberRoutes } from "@vdt/subscriber";
import { CampagneRoutes } from "@vdt/campagne";
import { LogoutRoutes } from '@vdt/login'
import NotFound from "../components/NotFound";
import AdminLayout from "../components/layout/AdminLayout";
import { UserAuthRoutesTest } from "./auth/UserAuthRoutes";

export const Routes: Array<AppRouteProps> = [
    {
        path: ":lang",
        title: `VPostal.title`,
        children: [
            {
                path: "",
                element: <AdminLayout />,
                children: [
                    ...HomeRoutes,
                    ...NotificationRoutes,
                    ...AdminUserManagementRoutes,
                    ...TranslationRoutes,
                    ...SettingsRoutes,
                    ...SubscriberRoutes,
                    ...CampagneRoutes
                ],
            },
            ...UserAuthRoutesTest,
            ...LogoutRoutes,
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
];
