import { AppRoot, MainLogo, SuspenseErrorState } from "@vdt/component";
import React from "react";
import { useHareaError } from "./hook/useHareaError";
import { Routes } from "./page/Routes";

const App: React.FC = () => {
    const getHareaCodeInfo = useHareaError();

    const transformError = (error: Error) => {
        return getHareaCodeInfo(error);
    };

    return (
        <AppRoot
            routes={Routes}
            protectedRoutes="*"
            unProtectedRoutes={UNPROTECTED_ROUTES}
            loginPath={process.env.REACT_APP_LOGIN_URL}
            suspenseFallback={<MainLogo />}
            initialState={[
                { state: SuspenseErrorState, value: { transformError } },
            ]}
        />
    );
};
const UNPROTECTED_ROUTES = [
    "recrutement",
    "recrutement/:id",
    "formation",
    "formation/:id",
    "evaluation/:ke",
    "auth/signup",
    "auth/forgot-password",
];
export default App;
