import { AppRouteProps } from "@vdt/component";
import React from "react";

const SettingsOrganisationContainer = React.lazy(() => import("./containers/SettingsOrganisationContainer"))

export const SettingsOrganisationRoutes: Array<AppRouteProps> = [
    {
        path: 'organisation',
        title: 'VDT.VPostal.Bo.Settings.Organisation.Title',
        element: <SettingsOrganisationContainer/>
    }
]